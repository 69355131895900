/* eslint-disable react-hooks/exhaustive-deps */

import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { apiWords, IWord } from 'api/api-words';
import { WORDS_TABLE_HEADERS } from 'consts';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';
import { useLesson } from 'hooks';
import useDebounce from 'hooks/useDebounce';
import { templateString } from './const';
import styles from './Content.module.css';

import Button from 'components/atoms/Button';
import ConfirmationModal from 'components/atoms/ConfirmationModal';
import {
  IconCopy,
  IconImport,
  IconPen,
  IconPlus,
  IconTrash,
  IconUpload
} from 'components/atoms/icons';
import MenuButton, { MenuItems } from 'components/atoms/Menu/MenuButton';
import Modal from 'components/atoms/Modal';
import SearchField from 'components/atoms/SearchField/SearchField';
import ImageUploadForm from 'components/organisms/ImageUploadForm';
import { useDeleteWordsMutation } from 'hooks/mutations';
import { DeleteWordForm } from '..';
import AddWordForm from '../AddWordForm';
import ContentSkeleton from './ContentSkeleton';

type Params = {
  lessonId: string;
};

type ContentProps = {
  handleUploadFile: (event: ChangeEvent<HTMLInputElement>) => void;
  fileUploadLoading: boolean;
  notionTextUploadLoading: boolean;
  toggleIsNotionTextParseOpen: () => void;
};

const Content: FC<ContentProps> = ({
  handleUploadFile,
  fileUploadLoading,
  notionTextUploadLoading,
  toggleIsNotionTextParseOpen
}) => {
  const navigate = useNavigate();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { lessonId } = useParams<keyof Params>() as Params;
  const { mutate: deleteWordsMutate, isLoading: deleteWordsLoading } = useDeleteWordsMutation();
  const [lesson, lessonLoading, refetchLesson] = useLesson({
    lessonId,
    sections: false,
    words: true
  });

  const [value, setValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [isMenuMustBeOpened, setIsMenuMustBeOpened] = useState<boolean>(false);

  const debouncedSearchValue = useDebounce(value, 500);
  useEffect(() => {
    setSearchValue(value);
  }, [debouncedSearchValue]);

  const [currentWords, setCurrentWords] = useState<IWord[]>([]);
  useEffect(() => {
    if (lesson && lesson.words) {
      const words =
        searchValue.length > 0
          ? lesson.words.filter(({ id, original, translation }) => {
              const normalizedId = id.toLowerCase();
              const normalizedOriginal = original.toLowerCase();
              const normalizedTranslation = translation.toLowerCase();
              const normalizedSearchValue = searchValue.toLowerCase();

              return (
                normalizedId.includes(normalizedSearchValue) ||
                normalizedOriginal.includes(normalizedSearchValue) ||
                normalizedTranslation.includes(normalizedSearchValue)
              );
            })
          : lesson.words;

      setCurrentWords(words);
    }
  }, [lesson, searchValue]);

  const [isImageUploadFormModalActive, setImageUploadFormModalActive] = useState<boolean>(false);
  const [editWord, setEditWord] = useState<IWord | null>(null);

  const { mutate: setWordImageMutate } = useMutation(
    ({ wordId, image }: { wordId: string; image: File }) => {
      return apiWords.setWordImage({ wordId, body: { image } });
    },
    {
      onSuccess() {
        notifySuc('Картинка загружена');
        refetchLesson();
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const [addWordModalActive, setAddWordModalActive] = useState<boolean>(false);
  const [confirmationModalActive, setConfirmationModalActive] = useState(false);
  const [deleteAllWordsModalActive, setDeleteAllWordsModalActive] = useState<boolean>(false);

  const handleFileDownload = () => {
    const blob = new Blob([templateString], { type: 'text/csv;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'template.csv';
    link.click();
    URL.revokeObjectURL(url);
  };

  const handleDeleteAllWords = () => {
    setDeleteAllWordsModalActive(true);
  };

  const deleteAllWords = () => {
    const wordIds: string[] = lesson?.words?.map(({ id }) => id) || [];

    deleteWordsMutate(
      { lessonId, body: { wordIds } },
      {
        onSuccess: () => {
          setDeleteAllWordsModalActive(false);
        }
      }
    );
  };

  const menuItemsList = useMemo(() => {
    return [
      {
        items: [
          {
            title: 'Добавить одно слово',
            onClick: () => setAddWordModalActive(true),
            icon: <IconPlus color='#5770F3' />
          }
        ]
      },
      {
        title: 'Таблица',
        items: [
          {
            title: 'Скачать шаблон Csv',
            onClick: handleFileDownload,
            icon: <IconImport color='#5770F3' />
          },
          {
            title: 'Загрузить слова из Csv',
            onClick: () => {
              setIsMenuMustBeOpened(true);
              inputFileRef.current?.click();
            },
            icon: <IconUpload color='#5770F3' />,
            wrapper: children => {
              return (
                <label htmlFor='file'>
                  {children}

                  <input
                    type='file'
                    id='file'
                    className='hidden'
                    accept='text/csv'
                    onChange={handleUploadFile}
                    ref={inputFileRef}
                  />
                </label>
              );
            }
          }
        ]
      },
      {
        title: 'Notion',
        items: [
          {
            title: 'Добавить слова из Notion',
            onClick: toggleIsNotionTextParseOpen,
            icon: <IconCopy color='#5770F3' />
          }
        ]
      }
    ] as MenuItems;
  }, [handleUploadFile]);

  if (lessonLoading || fileUploadLoading || notionTextUploadLoading) return <ContentSkeleton />;
  if (!lesson) return <h2>Не удалось загрузить курс</h2>;

  return (
    <>
      <div className={styles.container}>
        <header className={styles.header}>
          <h2 className={styles.header__title}>Слова к уроку</h2>

          <MenuButton
            items={menuItemsList}
            className='mr-2'
            isMustBeOpened={isMenuMustBeOpened}
            setIsMustBeOpened={setIsMenuMustBeOpened}
            title='Добавить слова к уроку'
          />

          <Button
            type='button'
            variant='warning'
            title='Удалить все слова'
            className='h-[50px] rounded-[62px]'
            onClick={handleDeleteAllWords}
          />
        </header>

        <div className={styles.table}>
          <SearchField
            id={'search'}
            type={'text'}
            className='mb-[26px] flex-auto'
            placeholder={'Поиск по ID, на корейском, на русском'}
            required={false}
            value={value}
            setState={setValue}
          />

          <div className={styles.table__headers}>
            <span className={`${styles.table__header} ${styles.table__header_index}`}>
              {WORDS_TABLE_HEADERS[0]}
            </span>

            <span className={`${styles.table__header} ${styles.table__header_image}`}>
              {WORDS_TABLE_HEADERS[1]}
            </span>

            <span className={`${styles.table__header} ${styles.table__header_kr}`}>
              {WORDS_TABLE_HEADERS[2]}
            </span>

            <span className={`${styles.table__header} ${styles.table__header_ru}`}>
              {WORDS_TABLE_HEADERS[3]}
            </span>

            <span className={`${styles.table__header} ${styles.table__header_actions}`}>
              {WORDS_TABLE_HEADERS[4]}
            </span>
          </div>

          <div className={styles.table__rows}>
            {currentWords.length > 0 ? (
              <>
                {currentWords.map((word, i) => {
                  return (
                    <div
                      className={`${styles.table__row} ${
                        i % 2 === 1 ? styles.table__row_even : ''
                      }`}
                      key={word.id}
                    >
                      <div className={`${styles.table__cell} ${styles.table__cell_index}`}>
                        <span title={`${word.index}.`}>{word.index}.</span>
                      </div>

                      <div className={`${styles.table__cell} ${styles.table__cell_image}`}>
                        <button
                          className={`${styles.image} ${
                            word.imageUrl ? '' : styles.image_placeholder
                          }`}
                          onClick={() => {
                            setEditWord(word);
                            setImageUploadFormModalActive(true);
                          }}
                        >
                          {word.imageUrl ? (
                            <>
                              <div className={styles.image__backdrop}></div>
                              <IconUpload className={styles.image__icon} color='white' />

                              <img src={word.imageUrl} alt={word.imageUrl} />
                            </>
                          ) : (
                            <IconUpload color='#5770F3' />
                          )}
                        </button>
                      </div>

                      <div className={`${styles.table__cell} ${styles.table__cell_original}`}>
                        <span>{word.original}</span>
                      </div>

                      <div className={`${styles.table__cell} ${styles.table__cell_translation}`}>
                        <span>{word.translation}</span>
                      </div>

                      <div className={`${styles.table__cell} ${styles.table__cell_actions}`}>
                        <button
                          onClick={() => navigate(`/dictionaries/catalog/${word.id}`)}
                          className={styles.buttonAction}
                        >
                          <IconPen color='#71798F' />
                        </button>

                        <button
                          onClick={() => {
                            setEditWord(word);
                            setConfirmationModalActive(true);
                          }}
                          className={styles.buttonAction}
                        >
                          <IconTrash color='#71798F' />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <span>Слов не найдено</span>
            )}
          </div>
        </div>
      </div>

      {isImageUploadFormModalActive && editWord && (
        <Modal onClose={() => setImageUploadFormModalActive(false)}>
          <ImageUploadForm
            imageUrl={editWord.imageUrl}
            setImage={(image: File) => setWordImageMutate({ wordId: editWord.id, image })}
            onCancel={() => setImageUploadFormModalActive(false)}
          />
        </Modal>
      )}

      {addWordModalActive && (
        <Modal onClose={() => setAddWordModalActive(false)}>
          <AddWordForm
            lessonId={lesson.id}
            wordIds={currentWords.map(word => word.id)}
            onCancel={() => setAddWordModalActive(false)}
          />
        </Modal>
      )}

      {confirmationModalActive && editWord && (
        <Modal onClose={() => setConfirmationModalActive(false)}>
          <DeleteWordForm
            lessonId={lesson.id}
            word={editWord}
            onSuccess={() => setConfirmationModalActive(false)}
            onCancel={() => setConfirmationModalActive(false)}
          />
        </Modal>
      )}

      {deleteAllWordsModalActive && (
        <ConfirmationModal
          title={<>Удалить все слова в уроке?</>}
          text={
            <>
              Слова будут{' '}
              <b>
                удалены навсегда из Общего Словаря и
                <br />
                из всех курсов и уроков
              </b>
              , к которым они относятся.
            </>
          }
          isDelete
          confirmButtonText='Удалить слова'
          onConfirm={deleteAllWords}
          onClose={() => setDeleteAllWordsModalActive(false)}
          isLoading={deleteWordsLoading}
        />
      )}
    </>
  );
};

export default Content;
