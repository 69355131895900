import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetUserInfoQuery } from 'hooks/queries';
import { useGetSubscriptionsQuery } from 'hooks/subscription';

import ButtonAdd from 'components/atoms/ButtonAdd';
import { SkeletonUserCourses } from '../SkeletonUserCourses';
import { SubscriptionCard } from './components';

type IParams = {
  id: string;
};

const UserSubscriptions: FC = () => {
  const navigate = useNavigate();
  const { id: userId } = useParams<keyof IParams>() as IParams;
  const { data: user, isLoading: userLoading } = useGetUserInfoQuery(userId);
  const { data: subscriptions, isLoading: subscriptionsLoading } = useGetSubscriptionsQuery({
    userId
  });

  const goToAddSubscriptions = () => {
    navigate(`/users/${userId}/subscriptions/add`);
  };

  if (userLoading || subscriptionsLoading) return <SkeletonUserCourses />;
  if (!subscriptions || !user) return <h2>Не удалось загрузить подписки пользователя</h2>;

  return (
    <div className='flex flex-1 flex-col'>
      <header className='flex h-[100px] items-center justify-between gap-[20px]'>
        <h1 className='text-[30px] font-[700] leading-[36px] text-[#20233A]'>Подписки</h1>
        <ButtonAdd
          type='button'
          variant='primary'
          size='big'
          title='Добавить подписку на курс'
          onClick={goToAddSubscriptions}
          className='self-auto'
        />
      </header>

      {!!subscriptions.length ? (
        <ul className='flex flex-col gap-[10px]'>
          {subscriptions.map(subscription => (
            <SubscriptionCard
              key={subscription.purchase.id}
              user={user}
              subscription={subscription}
            />
          ))}
        </ul>
      ) : (
        <h2>Список подписок пользователя пуст</h2>
      )}
    </div>
  );
};

export default UserSubscriptions;
