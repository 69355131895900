import { FC, useMemo, useState } from 'react';

import avatarPlaceholder from 'assets/images/svg/avatar.svg';

import { DEFAULT_EXPIRATION_SHIFT } from 'consts';
import { useDeleteSubscriptionsMutation } from 'hooks/subscription';
import { User } from 'models';
import { Subscription } from 'models/subscription';
import { calculateExpire, convertToLocalDate } from 'utils';

import Button from 'components/atoms/Button/Button';
import ConfirmationModal from 'components/atoms/ConfirmationModal';
import Modal from 'components/atoms/Modal';
import { IconPen, IconTrash } from 'components/atoms/icons';
import { ExtendSubscriptionForm, UpdateSubscriptionForm } from '..';

type SubscriptionCardProps = {
  user: User;
  subscription: Subscription;
};

const SubscriptionCard: FC<SubscriptionCardProps> = props => {
  const {
    user: { id: userId },
    subscription,
    subscription: {
      course: { id: courseId, title, level, imageUrl },
      purchase: {
        paymentDate,
        expirationDate,
        isActive,
        isDeclinedByAdmin,
        isExpired,
        isExtended,
        isPermanent
      }
    }
  } = props;

  const [updateSubscriptionModalActive, setUpdateSubscriptionModalActive] = useState(false);
  const [deleteSubscriptionModalActive, setDeleteSubscriptionModalActive] = useState(false);
  const [extendSubscriptionModalActive, setExtendSubscriptionModalActive] = useState(false);
  const { mutate: deleteSubscriptionsMutate, isLoading: deleteSubscriptionsLoading } =
    useDeleteSubscriptionsMutation();

  const deleteSubscription = () => {
    deleteSubscriptionsMutate(
      { userId, body: { courseIds: [courseId] } },
      { onSuccess: () => setDeleteSubscriptionModalActive(false) }
    );
  };

  const SubsriptionStatus = useMemo(() => {
    const isAboutToExpire = calculateExpire(expirationDate, DEFAULT_EXPIRATION_SHIFT);

    if (isAboutToExpire) {
      return (
        <>
          <span className='text-center text-[13px] font-[500] leading-[15px] text-[#ff4444]'>
            Подписка скоро закончится!
          </span>
          <Button
            variant='light'
            size='small'
            title='Продлить'
            className='h-[32px] rounded-[62px] px-[24px] py-[8px] text-[14px] font-[500] leading-[16px]'
            onClick={() => setExtendSubscriptionModalActive(true)}
          />
        </>
      );
    }
    if (isActive) {
      return (
        <span className='text-[13px] font-[500] leading-[15px] text-[#66C84D]'>
          Подписка активна
        </span>
      );
    }
    if (isDeclinedByAdmin) {
      return (
        <span className='text-[13px] font-[500] leading-[15px] text-[#8C9AAF]'>
          Подписка отключена администратором
        </span>
      );
    }
    if (isExtended) {
      return (
        <span className='text-[13px] font-[500] leading-[15px] text-[#8C9AAF]'>
          Подписка продлена
        </span>
      );
    }
    if (isExpired) {
      return (
        <>
          <span className='text-[13px] font-[500] leading-[15px] text-[#8C9AAF]'>
            Подписка закончилась
          </span>
          <Button
            variant='light'
            size='small'
            title='Продлить'
            className='h-[32px] rounded-[62px] px-[24px] py-[8px] text-[14px] font-[500] leading-[16px]'
            onClick={() => setExtendSubscriptionModalActive(true)}
          />
        </>
      );
    }
  }, [expirationDate, isActive, isDeclinedByAdmin, isExpired, isExtended]);

  return (
    <li
      className={
        'flex items-center justify-between gap-[24px] py-[16px] ' +
        'border-b-[1px] border-solid border-[#E9ECF0]'
      }
    >
      <div
        className={
          'flex flex-[45%] items-center gap-[16px] ' +
          'overflow-hidden text-ellipsis whitespace-nowrap'
        }
      >
        <div
          className='h-[64px] w-[64px] shrink-0 rounded-[20px]'
          // style={{ background: color }}
        >
          <img src={imageUrl || avatarPlaceholder} alt={imageUrl} className='h-full w-full' />
        </div>

        <div className='flex max-w-[calc(100%-80px)] flex-col items-start gap-[8px]'>
          <span
            title={`Уровень ${level}`}
            className={
              'text-[13px] font-[500] leading-[15px] text-[#5a667b] ' +
              'overflow-hidden whitespace-nowrap text-ellipsis'
            }
          >
            {`Уровень ${level}`}
          </span>
          <h3
            title={title}
            className={
              'max-w-[100%] text-[16px] font-[500] leading-[19px] text-[#20233a] ' +
              'overflow-hidden whitespace-nowrap text-ellipsis'
            }
          >
            {title}
          </h3>
        </div>
      </div>

      <div className='flex flex-[20%] flex-col items-center gap-[10px]'>{SubsriptionStatus}</div>

      <div className='flex flex-[20%]'>
        {isPermanent ? (
          <span className='text-[12px] leading-[15px] text-[#5a667b]'>Без срока</span>
        ) : (
          <div className='flex flex-col'>
            <span className='text-[12px] lowercase leading-[14px] text-[#5a667b]'>
              с {paymentDate ? convertToLocalDate(paymentDate) : '-'}
            </span>
            <span className='text-[12px] lowercase leading-[14px] text-[#5a667b]'>
              по {expirationDate ? convertToLocalDate(expirationDate) : '-'}
            </span>
          </div>
        )}
      </div>

      <div className='flex flex-[15%] items-center gap-[32px]'>
        <button
          type='button'
          className='flex h-[24px] w-[24px] items-center justify-center bg-transparent'
          onClick={() => setUpdateSubscriptionModalActive(true)}
        >
          <IconPen color='#71798F' />
        </button>

        <button
          type='button'
          className='flex h-[24px] w-[24px] items-center justify-center bg-transparent'
          onClick={() => setDeleteSubscriptionModalActive(true)}
        >
          <IconTrash color='#71798F' />
        </button>
      </div>

      {extendSubscriptionModalActive && (
        <Modal onClose={() => setExtendSubscriptionModalActive(false)}>
          <ExtendSubscriptionForm
            userId={userId}
            subscription={subscription}
            onClose={() => setExtendSubscriptionModalActive(false)}
          />
        </Modal>
      )}

      {updateSubscriptionModalActive && (
        <Modal onClose={() => setUpdateSubscriptionModalActive(false)}>
          <UpdateSubscriptionForm
            userId={userId}
            subscription={subscription}
            onClose={() => setUpdateSubscriptionModalActive(false)}
          />
        </Modal>
      )}

      {deleteSubscriptionModalActive && (
        <ConfirmationModal
          title={
            <>
              Удалить подписку <br />
              {title}?
            </>
          }
          text={
            <>
              Курс будет удален из <b>доступных</b> пользователю. Его можно будет добавить
              пользователю <b>позже</b>.
            </>
          }
          isDelete
          confirmButtonText='Удалить подписку'
          onConfirm={deleteSubscription}
          onClose={() => setDeleteSubscriptionModalActive(false)}
          isLoading={deleteSubscriptionsLoading}
        />
      )}
    </li>
  );
};

export default SubscriptionCard;
