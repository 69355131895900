import { DealType } from 'models';

export function paidFrom(type: DealType) {
  if (type === DealType.AMO) {
    return 'AmoCRM';
  }
  if (type === DealType.APPLE) {
    return 'Apple';
  }
  if (type === DealType.GOOGLE_PLAY) {
    return 'Google Play';
  }
}
