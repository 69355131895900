import React, { useState } from 'react';

import styles from './ModuleCard.module.css';

import { IModule } from 'api/api-courses';
import { CheckMarkIcon } from 'components/atoms/icons';
import LessonCard from '../LessonCard';

interface ModuleCardProps {
  courseId: string;
  module: IModule;
  index: number;
}

const ModuleCard: React.FC<ModuleCardProps> = ({
  courseId,
  module: { id: moduleId, title, lessons },
  index
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <li className={styles.container}>
      <div
        className={`${styles.header} ${isOpen ? 'mb-[16px]' : ''}`}
        onClick={e => {
          e.stopPropagation();
          setIsOpen(prev => !prev);
        }}
      >
        <span className='absolute left-0 top-0 h-full w-[3px] rounded-[14px] bg-[#5770F3]'></span>

        <h3 className={styles.title}>{title}</h3>

        <button className={styles.buttonOpen}>
          {isOpen ? (
            <CheckMarkIcon className='rotate-90 text-[#71798F]' />
          ) : (
            <CheckMarkIcon className='-rotate-90 text-[#71798F]' />
          )}
        </button>
      </div>

      {isOpen && (
        <ul className={styles.lessons}>
          {lessons.length > 0 ? (
            <>
              {lessons
                .sort((a, b) => a.order - b.order)
                .map((lesson, i) => (
                  <LessonCard
                    key={lesson.id}
                    courseId={courseId}
                    moduleId={moduleId}
                    moduleOrder={index + 1}
                    moduleTitle={title}
                    lesson={lesson}
                    index={i}
                  />
                ))}
            </>
          ) : (
            <span>Пока в данном модуле нет уроков</span>
          )}
        </ul>
      )}
    </li>
  );
};

export default ModuleCard;
