import { FC, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { apiWords, IPagination, IWord } from 'api/api-words';
import { WORDS_PER_PAGE, WORDS_TABLE_HEADERS } from 'consts';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';
import useDebounce from 'hooks/useDebounce';
import styles from './CatalogPage.module.css';

import { IconBack, IconPen, IconTrash, IconUpload } from 'components/atoms/icons';
import Modal from 'components/atoms/Modal';
import SearchField from 'components/atoms/SearchField/SearchField';
import BePagintaion from 'components/molecules/BePagination/BePagination';
import DeleteWordForm from 'components/organisms/DeleteWordForm';
import ImageUploadForm from 'components/organisms/ImageUploadForm';
import ContentSkeleton from './ContentSkeleton';

const CatalogPage: FC = () => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [value, setValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [withFavorites, setWithFavorites] = useState<boolean>(false);

  const debouncedSearchValue = useDebounce(value, 500);
  useEffect(() => {
    setCurrentPage(1);
    setSearchValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue]);

  // const [currentWordsData, setCurrentWordsData] = useState<IWordData | null>(null);
  const [currentWords, setCurrentWords] = useState<IWord[]>([]);
  const [currentPagination, setCurrentPagination] = useState<IPagination | null>(null);

  const {
    isLoading,
    data,
    refetch: refetchWords
  } = useQuery(
    [
      `/words/all?page=${currentPage}&itemsPerPage=${WORDS_PER_PAGE}&letters=${searchValue}&withFavorites=${withFavorites}`,
      currentPage,
      searchValue
    ],
    async () =>
      await apiWords.getAllWords({
        page: currentPage,
        itemsPerPage: WORDS_PER_PAGE,
        letters: searchValue,
        withFavorites
      }),
    {
      keepPreviousData: true,
      onError(error) {
        handleError(error);
      }
    }
  );
  const wordsData = useMemo(() => data, [data]);

  useEffect(() => {
    if (wordsData) {
      setCurrentPage(wordsData.pagination.currentPage);

      // setCurrentWordsData(wordsData);
      setCurrentWords(wordsData.data);
      setCurrentPagination(wordsData.pagination);
    }
  }, [wordsData]);

  const [isImageUploadFormModalActive, setImageUploadFormModalActive] = useState<boolean>(false);
  const [isDeleteWordModalActive, setDeleteWordModalActive] = useState(false);
  const [editWord, setEditWord] = useState<IWord | null>(null);

  const { mutate: setWordImageMutate } = useMutation(
    ({ wordId, image }: { wordId: string; image: File }) => {
      return apiWords.setWordImage({ wordId, body: { image } });
    },
    {
      onSuccess() {
        notifySuc('Картинка загружена');
        refetchWords();
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <button className={styles.sidebar__back} onClick={() => navigate(-1)}>
          <IconBack className='text-[#71798F]' />
          <p>Назад</p>
        </button>
      </div>

      {isLoading ? (
        <ContentSkeleton />
      ) : (
        <div className={styles.content}>
          <div className={styles.header}>
            <h2 className={styles.title}>Корейско-русский словарь</h2>
            <h3 className={styles.subtitle}>Общий каталог слов</h3>
          </div>

          <div className={styles.table}>
            <SearchField
              id={'search'}
              type={'text'}
              placeholder={'Поиск по ID, на корейском, на русском'}
              className='mb-[16px] h-[80px] flex-auto'
              required={false}
              value={value}
              setState={setValue}
            />

            <>
              <div className={styles.table__headers}>
                <span className={`${styles.table__header} ${styles.table__header_index}`}>
                  {WORDS_TABLE_HEADERS[0]}
                </span>

                <span className={`${styles.table__header} ${styles.table__header_image}`}>
                  {WORDS_TABLE_HEADERS[1]}
                </span>

                <span className={`${styles.table__header} ${styles.table__header_kr}`}>
                  {WORDS_TABLE_HEADERS[2]}
                </span>

                <span className={`${styles.table__header} ${styles.table__header_ru}`}>
                  {WORDS_TABLE_HEADERS[3]}
                </span>

                <span className={`${styles.table__header} ${styles.table__header_actions}`}>
                  {WORDS_TABLE_HEADERS[4]}
                </span>
              </div>

              <div className={styles.table__rows}>
                {currentWords.length > 0 ? (
                  <>
                    {currentWords.map((word, i) => {
                      return (
                        <div
                          className={`${styles.table__row} ${
                            i % 2 === 1 ? styles.table__row_even : ''
                          }`}
                          key={word.id}
                        >
                          <div className={`${styles.table__cell} ${styles.table__cell_index}`}>
                            <span title={`${word.index}.`}>{word.index}.</span>
                          </div>

                          <div className={`${styles.table__cell} ${styles.table__cell_image}`}>
                            <button
                              className={`${styles.image} ${
                                word.imageUrl ? '' : styles.image_placeholder
                              }`}
                              onClick={() => {
                                setEditWord(word);
                                setImageUploadFormModalActive(true);
                              }}
                            >
                              {word.imageUrl ? (
                                <>
                                  <div className={styles.image__backdrop}></div>
                                  <IconUpload className={styles.image__icon} color='white' />

                                  <img src={word.imageUrl} alt={word.imageUrl} />
                                </>
                              ) : (
                                <IconUpload color='#5770F3' />
                              )}
                            </button>
                          </div>

                          <div className={`${styles.table__cell} ${styles.table__cell_original}`}>
                            <span>{word.original}</span>
                          </div>

                          <div
                            className={`${styles.table__cell} ${styles.table__cell_translation}`}
                          >
                            <span>{word.translation}</span>
                          </div>

                          <div className={`${styles.table__cell} ${styles.table__cell_actions}`}>
                            <button
                              className={styles.buttonAction}
                              onClick={() => navigate(`/dictionaries/catalog/${word.id}`)}
                            >
                              <IconPen color='#71798F' />
                            </button>

                            <button
                              className={styles.buttonAction}
                              onClick={() => {
                                setEditWord(word);
                                setDeleteWordModalActive(true);
                              }}
                            >
                              <IconTrash color='#71798F' />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <span>Результатов не найдено</span>
                )}
              </div>

              <div className={styles.table__pagination}>
                {currentPagination && currentPagination.totalItems > WORDS_PER_PAGE && (
                  <BePagintaion pagination={currentPagination} setCurrentPage={setCurrentPage} />
                )}
              </div>
            </>
          </div>
        </div>
      )}

      {isImageUploadFormModalActive && editWord && (
        <Modal onClose={() => setImageUploadFormModalActive(false)}>
          <ImageUploadForm
            imageUrl={editWord.imageUrl}
            setImage={(image: File) => setWordImageMutate({ wordId: editWord.id, image })}
            onCancel={() => setImageUploadFormModalActive(false)}
          />
        </Modal>
      )}

      {isDeleteWordModalActive && editWord && (
        <Modal onClose={() => setDeleteWordModalActive(false)}>
          <DeleteWordForm
            wordId={editWord.id}
            currentWord={editWord.translation}
            onSuccess={() => refetchWords()}
            onCancel={() => setDeleteWordModalActive(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default CatalogPage;
