/* eslint-disable react-hooks/exhaustive-deps */

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FC, MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ISection } from 'api/api-courses';
import styles from './SectionCard.module.css';

import Modal from 'components/atoms/Modal';
import {
  IconCopy,
  IconCopyLink,
  IconDragHandle,
  IconPaste,
  IconPen,
  IconTrash
} from 'components/atoms/icons';
import { creatLinkToCourseSection } from 'consts';
import { notifySuc } from 'helpers/notification';
import DeleteSectionForm from '../DeleteSectionForm';
import { CopySectionForm, MoveSectionForm } from './components';

interface SectionCardProps {
  section: ISection;
  lessonId: string;
  courseId: string;
  refetchLesson: () => void;
  moduleId?: string;
  moduleOrder?: number;
  moduleTitle?: string;
}

const SectionCard: FC<SectionCardProps> = ({
  section,
  section: { id: sectionId, title, amountSteps, order },
  lessonId,
  courseId,
  refetchLesson,
  moduleId,
  moduleOrder,
  moduleTitle
}) => {
  const navigate = useNavigate();
  const [isCopySectionModalActive, setCopySectionModalActive] = useState(false);
  const [isPasteSectionModalActive, setPasteSectionModalActive] = useState(false);

  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } =
    useSortable({
      id: sectionId
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const navigateToSectionEdit = () => {
    if (moduleId) {
      navigate(
        `/courses/${courseId}/${moduleId}/${lessonId}?moduleOrder=${moduleOrder}&moduleTitle=${moduleTitle}&edit=true&step=1&sectionId=${sectionId}`
      );
    } else {
      navigate(
        `/courses/${courseId}/no-module/${lessonId}?edit=true&step=1&sectionId=${sectionId}`
      );
    }
  };

  const handleCopyLinkToClipboard = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    navigator.clipboard
      .writeText(creatLinkToCourseSection({ courseId, moduleId, lessonId, sectionId }))
      .then(() => notifySuc('Ссылка скопирована'));
  };

  const [isDeleteSectionModalActive, setDeleteSectionModalActive] = useState<boolean>(false);

  return (
    <>
      <li
        className={styles.container}
        ref={setNodeRef}
        style={style}
        {...attributes}
        onClick={e => {
          e.stopPropagation();
          navigateToSectionEdit();
        }}
      >
        <button className={styles.dragHandle} ref={setActivatorNodeRef} {...listeners}>
          <IconDragHandle color='#A6B0C9' />
        </button>

        <div className={styles.text}>
          <h4 className={styles.heading}>№{order}</h4>
          <h3 className={styles.title} title={title}>
            {title}
          </h3>
        </div>

        <div className={styles.controls}>
          <button title='Скопировать ссылку на раздел' onClick={handleCopyLinkToClipboard}>
            <IconCopyLink />
          </button>

          <button
            title='Скопировать'
            className={styles.buttonCopy}
            onClick={event => {
              event.stopPropagation();
              setCopySectionModalActive(true);
            }}
          >
            <IconCopy color='#71798F' />
          </button>

          <button
            title='Переместить'
            className={styles.buttonPaste}
            onClick={event => {
              event.stopPropagation();
              setPasteSectionModalActive(true);
            }}
          >
            <IconPaste color='#71798F' />
          </button>

          <button title='Редактировать' className={styles.buttonEdit}>
            <IconPen color='#71798F' />
          </button>

          <button
            title='Удалить'
            className={styles.buttonDelete}
            onClick={e => {
              e.stopPropagation();
              setDeleteSectionModalActive(true);
            }}
          >
            <IconTrash color='#A1AABC' />
          </button>
        </div>

        <div className={styles.stepsAmount}>{amountSteps ? amountSteps : 0}</div>
      </li>

      {isCopySectionModalActive && (
        <Modal onClose={() => setCopySectionModalActive(false)}>
          <CopySectionForm
            courseId={courseId}
            lessonId={lessonId}
            section={section}
            onCancel={() => setCopySectionModalActive(false)}
          />
        </Modal>
      )}

      {isPasteSectionModalActive && (
        <Modal onClose={() => setPasteSectionModalActive(false)}>
          <MoveSectionForm
            courseId={courseId}
            currentLessonId={lessonId}
            section={section}
            onCancel={() => setPasteSectionModalActive(false)}
          />
        </Modal>
      )}

      {isDeleteSectionModalActive && (
        <Modal onClose={() => setDeleteSectionModalActive(false)}>
          <DeleteSectionForm
            lessonId={lessonId}
            sectionId={sectionId}
            currentTitle={title}
            onSuccess={refetchLesson}
            onCancel={() => setDeleteSectionModalActive(false)}
          />
        </Modal>
      )}
    </>
  );
};

export default SectionCard;
