/* eslint-disable react-hooks/exhaustive-deps */

import { DndContext, DragEndEvent } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Dispatch, FC, MouseEvent, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { apiCourses, ILesson, ISection, UpdateLessonDto, UpdateOrderData } from 'api/api-courses';
import schoolPNG from 'assets/images/png/school.png';
import { creatLinkToCourseLesson, FORM_ERROR_MESSAGES } from 'consts';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';
import { useLesson } from 'hooks';
import { useUpdateLessonMutation, useUploadLessonImageMutation } from 'hooks/lesson/mutations';
import { useUpdateSectionOrdersMutation } from 'hooks/section';
import styles from './LessonCard.module.css';

import Button from 'components/atoms/Button';
import ButtonAdd from 'components/atoms/ButtonAdd';
import ConfirmationModal from 'components/atoms/ConfirmationModal';
import {
  CheckMarkIcon,
  IconCopy,
  IconCopyLink,
  IconDragHandle,
  IconPaste,
  IconPen,
  IconTrash
} from 'components/atoms/icons';
import Input from 'components/atoms/Input';
import Modal from 'components/atoms/Modal';
import ImageUploadForm from '../ImageUploadForm';
import SectionCard from '../SectionCard';
import { CopyLessonForm, MoveLessonForm } from './components';

type FormValues = {
  newTitle: string;
  newSubtitle: string;
};

interface LessonCardProps {
  lesson: ILesson;
  isLessonsEdit: boolean;
  setIsLessonsEdit: Dispatch<SetStateAction<boolean>>;
  refetchCourse: () => void;
  courseId: string;
  moduleId?: string;
  moduleTitle?: string;
  moduleOrder?: number;
}

const LessonCard: FC<LessonCardProps> = props => {
  const {
    lesson,
    lesson: { id: lessonId, title, subtitle, imageUrl, order },
    isLessonsEdit,
    setIsLessonsEdit,
    refetchCourse,
    courseId,
    moduleId,
    moduleTitle,
    moduleOrder
  } = props;
  const navigate = useNavigate();
  const { mutate: updateSectionOrdersMutate } = useUpdateSectionOrdersMutation();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty }
  } = useForm<FormValues>({
    mode: 'onSubmit',
    defaultValues: { newTitle: title, newSubtitle: subtitle || '' }
  });

  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } =
    useSortable({
      id: lessonId
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const [lessonWithSections, lessonLoading, refetchLesson] = useLesson({
    lessonId,
    sections: true,
    amountSteps: true,
    enabled: false,
    keepPreviousData: true
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      refetchLesson();
    }
  }, [isOpen]);

  // It is needed for optimistic updates.
  const [currentSections, setCurrentSections] = useState<ISection[]>(() => {
    if (lessonWithSections && lessonWithSections.sections) {
      return lessonWithSections.sections.sort((a, b) => a.order - b.order);
    }
    return [];
  });

  useEffect(() => {
    if (lessonWithSections && lessonWithSections.sections) {
      const sortedSections = lessonWithSections.sections.sort((a, b) => a.order - b.order);
      setCurrentSections(sortedSections);
    }
  }, [lessonWithSections]);

  const updateSectionOrders = (sections: ISection[]) => {
    const body: UpdateOrderData[] = sections.map((section, index) => ({
      id: section.id,
      newOrder: index + 1
    }));
    updateSectionOrdersMutate({ lessonId, body });
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over.id) {
      const oldIndex = currentSections.findIndex(section => section.id === active.id);
      const newIndex = currentSections.findIndex(section => section.id === over.id);
      const newSections = arrayMove(currentSections, oldIndex, newIndex);
      setCurrentSections(newSections);
      updateSectionOrders(newSections);
    }
  };

  // check if the number of sections has changed
  useEffect(() => {
    if (
      !!lessonWithSections?.sections?.length &&
      !!currentSections.length &&
      lessonWithSections.sections.length !== currentSections.length
    ) {
      updateSectionOrders(lessonWithSections.sections);
    }
  }, [lessonWithSections]);

  const [isCopyModuleModalActive, setCopyModuleModalActive] = useState(false);
  const [isPasteModuleModalActive, setPasteModuleModalActive] = useState(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  const [newImage, setNewImage] = useState<File | null>(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!newImage) {
      setImagePreviewUrl(null);
      return;
    }

    const objectUrl = URL.createObjectURL(newImage);
    setImagePreviewUrl(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [newImage]);

  const [isImageUploadFormModalActive, setImageUploadFormModalActive] = useState<boolean>(false);

  const { mutateAsync: updateLessonMutateAsync, isLoading: updateLessonLoading } =
    useUpdateLessonMutation();
  const { mutateAsync: setLessonImageMutateAsync, isLoading: setLessonImageLoading } =
    useUploadLessonImageMutation();

  const onSubmit = async (data: FormValues) => {
    const { newTitle, newSubtitle } = data;

    if (newImage) {
      await setLessonImageMutateAsync({ courseId, lessonId, image: newImage });
    }

    const trimmedNewTitle = newTitle.trim();
    const trimmedNewSubtitle = newSubtitle.trim();
    if ((trimmedNewTitle && trimmedNewTitle !== title) || trimmedNewSubtitle !== subtitle) {
      const updateLessonData: UpdateLessonDto = {
        courseId,
        lessonId,
        body: { title: trimmedNewTitle, subtitle: trimmedNewSubtitle }
      };
      await updateLessonMutateAsync(updateLessonData);
    }

    reset({ newTitle, newSubtitle });
    setEditMode(false);
    setIsLessonsEdit(false);
  };

  const handleCopyLinkToClipboard = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    navigator.clipboard
      .writeText(creatLinkToCourseLesson({ courseId, moduleId, lessonId }))
      .then(() => notifySuc('Ссылка скопирована'));
  };

  const [confirmationModalActive, setConfirmationModalActive] = useState(false);

  const { mutate: deleteLessonMutate, isLoading: deleteLessonLoading } = useMutation(
    () => {
      return apiCourses.deleteLessons({ courseId, moduleId, lessonIds: [lessonId] });
    },
    {
      onSuccess() {
        setConfirmationModalActive(false);
        notifySuc('Урок удален');

        refetchCourse();
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  return (
    <>
      <li
        className={`${styles.container} ${editMode ? 'bg-transparent' : ''}`}
        ref={setNodeRef}
        style={style}
        {...attributes}
        onClick={e => {
          e.stopPropagation();
          setIsOpen(prev => !prev);
        }}
      >
        {editMode ? (
          // TODO move to separate component.
          <form
            className={styles.editForm}
            onSubmit={handleSubmit(onSubmit)}
            onReset={() => {
              setEditMode(false);
            }}
          >
            <div className={styles.editForm__left}>
              <div
                className={styles.newImage}
                onClick={e => {
                  e.stopPropagation();
                  setImageUploadFormModalActive(true);
                }}
              >
                {imagePreviewUrl ? (
                  <img src={imagePreviewUrl} alt={imagePreviewUrl} />
                ) : (
                  <img src={imageUrl} alt={imageUrl} />
                )}
              </div>

              <Input
                type='text'
                name='newSubtitle'
                variant='light'
                width='big'
                label='Подзаголовок'
                control={control}
                rules={{
                  required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
                  maxLength: { value: 64, message: 'Максимальная длина 64 символа' },
                  validate: {
                    doesntConsistOfSpaces: (value: any) => {
                      return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                    }
                  }
                }}
                loading={updateLessonLoading || setLessonImageLoading}
                containerClassName='grow-[1]'
              />

              <Input
                type='text'
                name='newTitle'
                variant='light'
                width='big'
                label='Название урока'
                control={control}
                rules={{
                  required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
                  maxLength: { value: 64, message: 'Максимальная длина 64 символа' },
                  validate: {
                    doesntConsistOfSpaces: (value: any) => {
                      return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                    }
                  }
                }}
                loading={updateLessonLoading || setLessonImageLoading}
                containerClassName='grow-[2]'
              />
            </div>

            <div className={styles.buttons}>
              <Button
                type='submit'
                variant='primary'
                title='Сохранить'
                isDisabled={!isDirty && !newImage}
                isLoading={updateLessonLoading || setLessonImageLoading}
              />

              <Button
                type='reset'
                variant='secondary'
                title='Отменить'
                onClick={() => {
                  reset();
                  setNewImage(null);
                  setIsLessonsEdit(false);
                }}
                isDisabled={updateLessonLoading || setLessonImageLoading}
              />
            </div>

            {isImageUploadFormModalActive && (
              <Modal onClose={() => setImageUploadFormModalActive(false)}>
                <ImageUploadForm
                  imageUrl={imageUrl}
                  setImage={(image: File) => setNewImage(image)}
                  onCancel={() => setImageUploadFormModalActive(false)}
                />
              </Modal>
            )}
          </form>
        ) : (
          <>
            <div className={styles.header}>
              <button className={styles.dragHandle} ref={setActivatorNodeRef} {...listeners}>
                <IconDragHandle color='#A6B0C9' />
              </button>

              <div className={styles.image}>
                <img src={imageUrl || schoolPNG} alt={imageUrl} />
              </div>

              <div className={styles.text}>
                <h4 className={styles.heading}>{subtitle || `Урок ${order}`}</h4>
                <h3 className={styles.title} title={title}>
                  {title}
                </h3>
              </div>

              <div className={styles.controls}>
                <button title='Скопировать ссылку на урок' onClick={handleCopyLinkToClipboard}>
                  <IconCopyLink />
                </button>

                <button
                  title='Скопировать'
                  className={styles.buttonCopy}
                  onClick={event => {
                    event.stopPropagation();
                    setCopyModuleModalActive(true);
                  }}
                >
                  <IconCopy color='#71798F' />
                </button>

                <button
                  title='Переместить'
                  className={styles.buttonPaste}
                  onClick={event => {
                    event.stopPropagation();
                    setPasteModuleModalActive(true);
                  }}
                >
                  <IconPaste color='#71798F' />
                </button>

                <button
                  title='Редактировать'
                  className={styles.buttonEdit}
                  disabled={isLessonsEdit}
                  onClick={e => {
                    e.stopPropagation();
                    setEditMode(true);
                    setIsLessonsEdit(true);
                  }}
                >
                  <IconPen color='#71798F' />
                </button>

                <button
                  title='Удалить'
                  className={styles.buttonDelete}
                  onClick={e => {
                    e.stopPropagation();
                    setConfirmationModalActive(true);
                  }}
                >
                  <IconTrash color='#A1AABC' />
                </button>
              </div>

              <button className={styles.buttonOpen}>
                {isOpen ? (
                  <CheckMarkIcon className='rotate-90 text-[#71798F]' />
                ) : (
                  <CheckMarkIcon className='-rotate-90 text-[#71798F]' />
                )}
              </button>
            </div>

            {isOpen && (
              <div className={styles.dropdown} onClick={e => e.stopPropagation()}>
                {lessonLoading ? (
                  <span>Загрузка...</span>
                ) : (
                  <>
                    {currentSections.length > 0 ? (
                      <DndContext onDragEnd={handleDragEnd}>
                        <SortableContext
                          items={currentSections}
                          strategy={verticalListSortingStrategy}
                        >
                          <ul className={styles.sections}>
                            <>
                              {currentSections.map(section => (
                                <SectionCard
                                  key={section.id}
                                  section={section}
                                  lessonId={lessonId}
                                  courseId={courseId}
                                  refetchLesson={() => refetchLesson()}
                                />
                              ))}
                            </>
                          </ul>
                        </SortableContext>
                      </DndContext>
                    ) : (
                      <span>Пока в данном уроке нет разделов</span>
                    )}
                  </>
                )}

                <div className={styles.addSectionsToLesson}>
                  <span className={styles.line}></span>

                  <ButtonAdd
                    variant='light'
                    type='button'
                    title='Добавить новый раздел'
                    onClick={() => {
                      if (moduleId) {
                        navigate(
                          `/courses/${courseId}/${moduleId}/${lessonId}?moduleOrder=${moduleOrder}&moduleTitle=${moduleTitle}`
                        );
                      } else {
                        navigate(`/courses/${courseId}/no-module/${lessonId}`);
                      }
                    }}
                  />

                  <span className={styles.line}></span>
                </div>
              </div>
            )}
          </>
        )}
      </li>

      {isCopyModuleModalActive && lesson && (
        <Modal onClose={() => setCopyModuleModalActive(false)}>
          <CopyLessonForm
            courseId={courseId}
            lesson={lesson}
            refetchCourse={refetchCourse}
            onCancel={() => setCopyModuleModalActive(false)}
          />
        </Modal>
      )}

      {isPasteModuleModalActive && lesson && (
        <Modal onClose={() => setPasteModuleModalActive(false)}>
          <MoveLessonForm
            courseId={courseId}
            lesson={lesson}
            refetchCourse={refetchCourse}
            onCancel={() => setPasteModuleModalActive(false)}
          />
        </Modal>
      )}

      {confirmationModalActive && (
        <ConfirmationModal
          title={
            <>
              Удалить урок <br />
              {title}?
            </>
          }
          text={
            <>
              Этот урок будет удален <b>навсегда</b>.<br />
              Вместе с ним будут удалены <b>все его шаги</b>
            </>
          }
          isDelete={true}
          confirmButtonText='Удалить урок'
          onConfirm={() => deleteLessonMutate()}
          onClose={() => setConfirmationModalActive(false)}
          isLoading={deleteLessonLoading}
        />
      )}
    </>
  );
};

export default LessonCard;
