import { FC, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { apiWords } from 'api/api-words';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';
import { useGetWordQuery } from 'hooks/queries';
import styles from './Sidebar.module.css';

import Modal from 'components/atoms/Modal';
import { GenerateIcon, IconBack, IconClose, IconUpload } from 'components/atoms/icons';
import ImageUploadForm from 'components/organisms/ImageUploadForm';
import SidebarSkeleton from './SidebarSkeleton';
import Button from 'components/atoms/Button';
import EmptyWord from 'assets/images/png/emptyWordBig.jpg';
import { GeneratedWordImageModal } from 'components/organisms';

type Params = { wordId: string };

const Sidebar: FC = () => {
  const navigate = useNavigate();
  const { wordId } = useParams<keyof Params>() as Params;
  const {
    data: word,
    isLoading: wordLoading,
    refetch: refetchWord
  } = useGetWordQuery({
    wordId,
    isFavorite: false,
    withReferences: true
  });

  const [isImageUploadFormModalActive, setImageUploadFormModalActive] = useState<boolean>(false);
  const [isShowGenerateImageModal, setIsShowGenerateImageModal] = useState<boolean>(false);

  const { mutate: setWordImageMutate } = useMutation(
    ({ wordId, image }: { wordId: string; image: File }) => {
      return apiWords.setWordImage({ wordId, body: { image } });
    },
    {
      onSuccess() {
        notifySuc('Картинка загружена');
        refetchWord();
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const { mutate: deleteImage, isLoading: isDeleteImageLoading } = useMutation(
    () => {
      return apiWords.deleteImage({ wordId });
    },
    {
      onSuccess() {
        notifySuc('Картинка удалена');
        refetchWord();
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const toggleShowGenerateImageModal = () => {
    setIsShowGenerateImageModal(prev => !prev);
  };

  if (wordLoading) return <SidebarSkeleton />;
  if (!word) return <h2>Не удалось загрузить слово</h2>;

  return (
    <div className={styles.sidebar}>
      <button className={styles.sidebar__back} onClick={() => navigate(-1)}>
        <IconBack className='text-[#71798F]' />
        <p>Назад</p>
      </button>

      <div className={styles.sidebar__info}>
        {word.imageUrl ? (
          <div className={styles.imageWrapper}>
            <button
              className={styles.imageDeleteButton}
              onClick={() => deleteImage()}
              disabled={isDeleteImageLoading}
            >
              <IconClose />
            </button>
            <img className={styles.image} src={word.imageUrl} alt={word.imageUrl} />
          </div>
        ) : (
          <img className={styles.image} src={EmptyWord} alt={word.imageUrl} />
        )}

        <Button
          title='Сгенерировать'
          iconPosition='left'
          icon={<GenerateIcon />}
          onClick={toggleShowGenerateImageModal}
        />
        <Button
          className={styles['upload-button']}
          icon={<IconUpload />}
          iconPosition='left'
          title='Загрузить'
          onClick={() => setImageUploadFormModalActive(true)}
          variant='light'
        />

        <div className={styles.sidebar__text}>
          <span>Файл формата PNG или JPG</span>
        </div>
      </div>

      {isImageUploadFormModalActive && (
        <Modal onClose={() => setImageUploadFormModalActive(false)}>
          <ImageUploadForm
            imageUrl={word?.imageUrl}
            setImage={(image: File) => setWordImageMutate({ wordId: word.id, image })}
            onCancel={() => setImageUploadFormModalActive(false)}
          />
        </Modal>
      )}
      {isShowGenerateImageModal && (
        <GeneratedWordImageModal
          handleClose={toggleShowGenerateImageModal}
          wordId={wordId}
          afterUpload={refetchWord}
        />
      )}
    </div>
  );
};

export default Sidebar;
