import { StylesConfig } from 'react-select';

import { IOption } from 'models';
import { PaidFrom } from 'models/subscription';

export const filterSelectStyles: StylesConfig = {
  control: (styles, { isFocused, isDisabled }) => ({
    ...styles,
    height: '54px',
    width: '260px',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'rgba(140, 154, 175, 0.25)',
    '&:hover': {
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'rgba(140, 154, 175, 0.25)'
    },
    boxShadow: isFocused ? 'none' : 'inherit',
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  }),
  option: (styles, { data, isFocused, isDisabled }) => ({
    ...styles,
    color: (data as IOption).color,
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: '500',
    backgroundColor: isFocused ? '#EFF1F4' : 'none',
    '&:active': {
      backgroundColor: '#EFF1F4'
    },
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  }),
  singleValue: (styles, { data, isDisabled }) => ({
    ...styles,
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: '500',
    color: (data as IOption).color,
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  })
};

export const PaidFromOptions = [
  { value: PaidFrom.ADMIN, label: 'Админ' },
  { value: PaidFrom.APP_STORE, label: 'AppStore' },
  { value: PaidFrom.GOOGLLE_PLAY, label: 'GooglePlay' },
  { value: PaidFrom.AMO_CRM, label: 'AMO Crm' }
];

export const filterPaidFromOptions: IOption[] = [
  { label: 'Все покупки', value: 'Все покупки' },
  ...PaidFromOptions
].map(paidFrom => ({
  value: paidFrom.value === 'Все покупки' ? '' : paidFrom.value,
  label: paidFrom.label,
  color: paidFrom.value === 'Все покупки' ? '#8C9AAF' : '#20233A'
}));

export const DEFAULT_PAGE = 1;

export enum SEARCH_PARAMS {
  PAID_FROM = 'paidFrom',
  SORT_ORDER = 'sortOrder',
  SEARCH = 'search',
  PAGE = 'page'
}
