import { OutputData } from '@editorjs/editorjs';
import { BlockToolData } from '@editorjs/editorjs/types/tools/block-tool-data';

import { COURSE_STATUS } from 'consts';
import {
  AudioPart,
  ChecklistItem,
  Pagination,
  StepFormat,
  Timecode,
  User,
  UserWithPurchase,
  ValidatedWord,
  ValidatedWordData,
  ValidatedWordsData
} from 'models';
import { instance } from './api';
import { IWord } from './api-words';

export interface ICourse {
  id: string;
  title: string;
  learningObjectives: string[];
  learningObjectivesWithIcons?: ILearningObjectivesWithIcons[];
  status: COURSE_STATUS;
  level: string;
  speakers: User[];
  order: number;
  attempts: number;
  imageUrl: string;
  miniImageUrl: string;
  color: string;
  modules: IModule[];
  checklist: ChecklistItem[];
  isShownTheoreticalMaterial?: boolean;
  isShownCarrotQuestChat?: boolean;
  isShownDictionary?: boolean;
  bannerImageUrl?: string;
  withModules?: boolean;
  lessons?: ILesson[];
}

export interface ILearningObjectivesWithIcons {
  iconLink: string;
  text: string;
}

export interface CreateCourseData {
  image: File;
  miniimage: File;
  title: string;
  isShownCarrotQuestChat: boolean;
  isShownDictionary: boolean;
  level: string;
  withModules: boolean;
}

export interface UpdateCourseData {
  courseId: string;
  body: {
    title?: string;
    learningObjectives?: string[];
    learningObjectivesWithIcons?: ILearningObjectivesWithIcons[];
    status?: COURSE_STATUS;
    level?: number;
    order?: number;
    attempts?: number;
    imageUrl?: string;
    miniImageUrl?: string;
    color?: string;
    checklist?: ChecklistItem[];
    isShownTheoreticalMaterial?: boolean;
    isShownCarrotQuestChat?: boolean;
    isShownDictionary?: boolean;
    bannerImageUrl?: string;
    withModules?: boolean;
  };
}

export interface IModule {
  id: string;
  title: string;
  order: number;
  lessons: ILesson[];
}

export interface CreateModuleData {
  title: string;
  order: number;
}

export interface UpdateModuleData {
  title?: string;
  order?: number;
}

export interface UpdateOrderData {
  id: string;
  newOrder: number;
}

export type UpdateSectionOrdersDto = {
  lessonId: string;
  body: UpdateOrderData[];
};

export interface updateModuleOrdersDto {
  courseId: string;
  data: UpdateOrderData[];
}

export interface ILesson {
  id: string;
  title: string;
  subtitle?: string;
  order: number;
  imageUrl: string;
  // TODO change to ISection[] | string[].
  sections?: ISection[];
  wordsCount: number;
  // TODO change to IWord[] | string[].
  words?: IWord[];
}

export interface CreateLessonDto {
  courseId?: string;
  moduleId?: string;
  body: {
    title: string;
    subtitle: string;
    image: File | null;
    order: number;
  };
}

export interface UpdateLessonDto {
  courseId: string;
  lessonId: string;
  body: {
    title?: string;
    subtitle?: string;
    imageUrl?: string;
    order?: number;
  };
}

export interface ISection {
  id: string;
  steps: IStep[];
  amountSteps?: number;
  title: string;
  order: number;
}

export interface CreateSectionData {
  title: string;
  order: number;
}

export interface UpdateSectionData {
  title?: string;
  order?: number;
}

export type UploadLessonImageDto = {
  courseId: string;
  lessonId: string;
  image: File;
};

export type CopySectionDto = {
  currentLessonId: string;
  lessonId: string;
  sectionId: string;
};

export type MoveSectionDto = {
  currentLessonId: string;
  lessonId: string;
  sectionId: string;
};

export enum STEP_TYPES {
  TEXT = 'text',
  VIDEO = 'video',
  MATCHING_PAIRS = 'matching pairs',
  CHOOSE_ANSWER = 'choose answer',
  CHOOSE_SENTENCE = 'choose sentence',
  MAKING_SENTENCE = 'making sentence',
  FILLING_MISSING_WORDS = 'filling missing words',
  INSERTING_MISSING_WORDS = 'inserting missing words',
  DIALOG = 'dialog'
}

export interface IStep {
  id: string;
  order: number;
  type: STEP_TYPES;
  createdAt?: string;
  updatedAt?: string;

  [key: string]: any | undefined;
}

export interface ITextStep extends IStep {
  id: string;
  content: OutputData;
}

export interface IVideoStep extends IStep {
  id: string;
  url: string;
  description: string;
  timeCodes: Timecode[];
}

export interface UpdateVideoStepData {
  order: number;
  url: string;
  description: string;
  timeCodes: Timecode[];
}

export interface CreateVideoStepData {
  order: number;
  url: string;
  description: string;
  timeCodes: Timecode[];
}

export interface CreateTextStepData {
  order: number;
  content: {
    blocks: BlockToolData;
  };
}

export interface UpdateTextStepData {
  order: number;
  content: {
    blocks: BlockToolData;
  };
}

export type UpdateStepsOrderData = {
  data: {
    id: string;
    newOrder: number;
    type: STEP_TYPES;
  }[];
};

export interface IPair {
  original: string;
  translation: string;
}

export interface IMatchingPairsStep extends IStep {
  id: string;
  type: STEP_TYPES.MATCHING_PAIRS;
  order: number;
  condition?: string;
  editableCondition: OutputData;
  pairs: IPair[];
  hint?: string;
  format: StepFormat;
  audioList?: AudioPart[];
  textForAudio?: string;
  url?: string;
}

export interface ICreateMatchingPairsStepData {
  order: number;
  condition?: string;
  editableCondition: OutputData;
  pairs: IPair[];
  hint?: string;
  format: StepFormat;
  audioList?: AudioPart[];
  textForAudio?: string;
  url?: string;
}

export interface IUpdateMatchingPairsStepData {
  order?: number;
  condition?: string;
  editableCondition?: OutputData;
  pairs?: IPair[];
  hint?: string;
  format?: StepFormat;
  audioList?: AudioPart[];
  textForAudio?: string;
  url?: string;
}

export interface IChooseAnswerStep extends IStep {
  id: string;
  condition?: string;
  editableCondition: OutputData;
  purpose?: string;
  editablePurpose: OutputData;
  answers: Array<{ word: string; correct: boolean }>;
  hint?: string;
  format: StepFormat;
  audioList?: AudioPart[];
  textForAudio?: string;
  url?: string;
}

export interface CreateChooseAnswerStepData {
  order: number;
  condition?: string;
  editableCondition: OutputData;
  purpose?: string;
  editablePurpose: OutputData;
  answers: Array<{
    word: string;
    correct: boolean;
  }>;
  hint?: string;
  format: StepFormat;
  audioList?: AudioPart[];
  textForAudio?: string;
  url?: string;
}

export interface UpdateChooseAnswerStepData {
  order?: number;
  condition?: string;
  editableCondition?: OutputData;
  purpose?: string;
  editablePurpose?: OutputData;
  answers?: Array<{
    word: string;
    correct: boolean;
  }>;
  hint?: string;
  format?: StepFormat;
  audioList?: AudioPart[];
  textForAudio?: string;
  url?: string;
}

export interface ChooseSentenceStep extends IStep {
  id: string;
  editableCondition: OutputData;
  editablePurpose: OutputData;
  answers: Array<{ sentence: string; correct: boolean }>;
  hint?: string;
  format: StepFormat;
  audioList?: AudioPart[];
  textForAudio?: string;
  url?: string;
}

export interface CreateChooseSentenceStep {
  order: number;
  editableCondition: OutputData;
  editablePurpose: OutputData;
  answers: Array<{
    sentence: string;
    correct: boolean;
  }>;
  hint?: string;
  format: StepFormat;
  audioList?: AudioPart[];
  textForAudio?: string;
  url?: string;
}

export interface UpdateChooseSentenceStep {
  order?: number;
  editableCondition?: OutputData;
  editablePurpose?: OutputData;
  answers?: Array<{
    sentence: string;
    correct: boolean;
  }>;
  hint?: string;
  format?: StepFormat;
  audioList?: AudioPart[];
  textForAudio?: string;
  url?: string;
}

export type CreateChooseSentenceStepDto = {
  sectionId: string;
  body: CreateChooseSentenceStep;
  image?: File;
  audio?: File;
};

export type UpdateChooseSentenceStepDto = {
  stepId: string;
  body: UpdateChooseSentenceStep;
  image?: File;
  audio?: File;
};

export type DeleteChooseSentenceStepDto = {
  sectionId: string;
  stepId: string;
};

export interface MakingSentenceStep extends IStep {
  id: string;
  type: STEP_TYPES.MAKING_SENTENCE;
  order: number;
  condition?: string;
  editableCondition: OutputData;
  purpose?: string;
  editablePurpose: OutputData;
  answer: string;
  extraWords: string[];
  hint?: string;
  format: StepFormat;
  audioList?: AudioPart[];
  textForAudio?: string;
  url?: string;
}

export interface CreateMakingSentenceStepData {
  order?: number;
  condition?: string;
  editableCondition?: OutputData;
  purpose?: string;
  editablePurpose?: OutputData;
  answer?: string;
  extraWords: string[];
  hint?: string;
  format?: StepFormat;
  audioList?: AudioPart[];
  textForAudio?: string;
  url?: string;
}

export interface UpdateMakingSentenceStepData {
  order?: number;
  condition?: string;
  editableCondition?: OutputData;
  purpose?: string;
  editablePurpose?: OutputData;
  answer?: string;
  extraWords: string[];
  hint?: string;
  format?: StepFormat;
  audioList?: AudioPart[];
  textForAudio?: string;
  url?: string;
}

export enum ContentPartType {
  WRAP = 'wrap'
}

export type ContentPart = {
  order: number;
  text: string;
  isWord: boolean;
  type?: ContentPartType;
};

export interface FillingWordsStep extends IStep {
  id: string;
  type: STEP_TYPES.FILLING_MISSING_WORDS;
  order: number;
  condition?: string;
  editableCondition: OutputData;
  purpose?: string;
  editablePurpose: OutputData;
  contentParts: ContentPart[];
  hint?: string;
  format: StepFormat;
  audioList?: AudioPart[];
  textForAudio?: string;
  url?: string;
}

export interface CreateFillingWordsStepData {
  order: number;
  condition?: string;
  editableCondition: OutputData;
  purpose?: string;
  editablePurpose: OutputData;
  contentParts: ContentPart[];
  hint?: string;
  format: StepFormat;
  audioList?: AudioPart[];
  textForAudio?: string;
  url?: string;
}

export interface UpdateFillingWordsStepData {
  order: number;
  condition?: string;
  editableCondition: OutputData;
  purpose?: string;
  editablePurpose: OutputData;
  contentParts: ContentPart[];
  hint?: string;
  format: StepFormat;
  audioList?: AudioPart[];
  textForAudio?: string;
  url?: string;
}

export interface InsertingWordsStep extends IStep {
  id: string;
  type: STEP_TYPES.INSERTING_MISSING_WORDS;
  order: number;
  condition?: string;
  editableCondition: OutputData;
  purpose?: string;
  editablePurpose: OutputData;
  incorrectWords: string[];
  contentParts: ContentPart[];
  hint?: string;
  format: StepFormat;
  audioList?: AudioPart[];
  textForAudio?: string;
  url?: string;
}

export interface CreateInsertingWordsStepData {
  order?: number;
  condition?: string;
  editableCondition?: OutputData;
  purpose?: string;
  editablePurpose?: OutputData;
  incorrectWords?: string[];
  contentParts?: ContentPart[];
  hint?: string;
  format?: StepFormat;
  audioList?: AudioPart[];
  textForAudio?: string;
  url?: string;
}

export interface UpdateInsertingWordsStepData {
  order?: number;
  condition?: string;
  editableCondition?: OutputData;
  purpose?: string;
  editablePurpose?: OutputData;
  incorrectWords?: string[];
  contentParts?: ContentPart[];
  hint?: string;
  format?: StepFormat;
  audioList?: AudioPart[];
  textForAudio?: string;
  url?: string;
}

export type DialogPart = {
  order: number;
  text: string;
};

export interface DialogStep extends IStep {
  id: string;
  type: STEP_TYPES.DIALOG;
  order: number;
  editableCondition: OutputData;
  editablePurpose: OutputData;
  contentParts: DialogPart[];
  hint?: string;
  format: StepFormat;
  audioList?: AudioPart[];
  url?: string;
}

export interface CreateDialogStep {
  order?: number;
  editableCondition?: OutputData;
  editablePurpose?: OutputData;
  contentParts?: DialogPart[];
  hint?: string;
  format?: StepFormat;
  audioList?: AudioPart[];
  url?: string;
}

export interface UpdateDialogStep {
  order?: number;
  editableCondition?: OutputData;
  editablePurpose?: OutputData;
  contentParts?: DialogPart[];
  hint?: string;
  format?: StepFormat;
  audioList?: AudioPart[];
  url?: string;
}

export type AddDialogStepDto = {
  sectionId: string;
  body: CreateDialogStep;
  image?: File;
  audio?: File;
};

export type UpdateDialogStepDto = {
  stepId: string;
  body: UpdateDialogStep;
  image?: File;
  audio?: File;
};

export type DeleteDialogStepDto = {
  sectionId: string;
  stepId: string;
};

export type SetStepImageDto = {
  stepId: string;
  type: STEP_TYPES;
  image: File;
};

export type GetCourseStudentsDto = {
  courseId: string;
  page: number;
  itemsPerPage: number;
  letters?: string;
  sortBy?: string;
};

export type GetCourseStudentsResponse = {
  pagination: Pagination;
  users: UserWithPurchase[];
};

export interface DeleteCourseUsersDto {
  courseId: string;
  emails: string[];
}

export interface AddCourseUsersDto {
  courseId: string;
  body: {
    emails: string[];
    expirationDate?: number;
    purchaseDate?: number;
    isPermanent: boolean;
  };
}

export type SendInvitesDto = {
  courseId: string;
  body: {
    users: { name: string; email: string }[];
    expirationDate?: number;
    purchaseDate?: number;
    isPermanent: boolean;
  };
};

export type DeleteWordsFromLessonDto = { lessonId: string; wordIds: string[] };

export type CopyLessonDto = {
  lessonId: string;
  targetCourseId?: string;
  targetModuleId?: string;
};

export type MoveLessonDto = {
  lessonId: string;
  targetModuleId?: string;
  targetCourseId?: string;
};

export const apiCourses = {
  createCourse: async ({
    title,
    image,
    miniimage,
    isShownCarrotQuestChat,
    isShownDictionary,
    level,
    withModules
  }: CreateCourseData) => {
    const formData = new FormData();

    formData.append('title', title);
    formData.append('image', image);
    formData.append('miniimage', miniimage);
    formData.append('isShownCarrotQuestChat', String(isShownCarrotQuestChat));
    formData.append('isShownDictionary', String(isShownDictionary));
    formData.append('level', level);
    formData.append('withModules', String(withModules));

    const response = await instance.post<ICourse>('courses', formData);
    return response.data;
  },
  getCourse: async (courseId: string) => {
    const response = await instance.get<ICourse>(`courses/one/${courseId}`);
    return response.data;
  },
  getAllCourses: async () => {
    const response = await instance.get<ICourse[]>('courses/all');
    return response.data;
  },
  getCourseStudents: async ({
    courseId,
    page,
    itemsPerPage,
    letters,
    sortBy
  }: GetCourseStudentsDto) => {
    let query = `courses/${courseId}/users?page=${page}&itemsPerPage=${itemsPerPage}`;

    if (!!letters?.length) {
      query += `&letters=${letters}`;
    }
    if (!!sortBy?.length) {
      const [sort, sortOrder] = sortBy.split('-');
      query += `&sort=${sort}&sortOrder=${sortOrder}`;
    }

    const response = await instance.get<GetCourseStudentsResponse>(query);
    return response.data;
  },
  deleteCourseUsers: async ({ courseId, emails }: DeleteCourseUsersDto) => {
    await instance.delete(`courses/${courseId}/users`, { data: { emails } });
  },
  addCourseUsers: async ({ courseId, body }: AddCourseUsersDto) => {
    await instance.post(`courses/add-course-to-users/${courseId}`, body);
  },
  sendInvites: async ({ courseId, body }: SendInvitesDto) => {
    const response = await instance.post(`courses/send-invitation-to-course/${courseId}`, body);
    return response.data;
  },
  updateCourseInfo: async ({ courseId, body }: UpdateCourseData) => {
    await instance.patch(`courses/${courseId}`, body);
  },
  setCourseImage: async (courseId: string, image: File) => {
    const formData = new FormData();
    formData.append('image', image);

    await instance.patch(`/courses/${courseId}/image`, formData);
  },
  setCourseMiniImage: async (courseId: string, image: File) => {
    const formData = new FormData();
    formData.append('image', image);

    await instance.patch(`/courses/${courseId}/mini-image`, formData);
  },
  deleteCourse: async (courseId: string) => {
    await instance.delete(`courses/${courseId}`);
  },

  addSpeakers: async (courseId: string, userIds: string[]) => {
    await instance.patch(`courses/${courseId}/speaker`, { userIds });
  },
  deleteSpeakers: async (courseId: string, userIds: string[]) => {
    await instance.delete(`courses/${courseId}/speaker`, { data: { userIds } });
  },

  addModules: async (courseId: string, modules: CreateModuleData[]) => {
    await instance.patch(`courses/${courseId}/module`, { modules });
  },
  deleteModules: async (courseId: string, moduleIds: string[]) => {
    await instance.delete(`courses/${courseId}/module`, { data: { moduleIds } });
  },
  moveModule: async (courseId: string, moduleId: string) => {
    await instance.put(`courses/${courseId}/module/${moduleId}`);
  },
  copyModule: async (courseId: string, moduleId: string) => {
    await instance.post(`courses/${courseId}/module/${moduleId}`);
  },
  updateModuleOrders: async ({ data }: updateModuleOrdersDto) => {
    const response = await instance.put<IModule[]>('modules/orders', { data });
    return response.data;
  },

  updateModule: async (moduleId: string, updateModuleData: UpdateModuleData) => {
    await instance.patch(`modules/${moduleId}`, updateModuleData);
  },
  updateModuleTitle: async (moduleId: string, title: string) => {
    await instance.patch(`modules/${moduleId}`, { title });
  },
  updateModuleOrder: async (moduleId: string, order: number) => {
    await instance.patch(`modules/${moduleId}`, { order });
  },
  createLesson: async ({
    courseId,
    moduleId,
    body: { title, subtitle, order, image }
  }: CreateLessonDto) => {
    const formData = new FormData();

    formData.append('title', title);
    formData.append('subtitle', subtitle);
    if (image) formData.append('image', image);
    formData.append('order', order.toString());

    if (moduleId) {
      await instance.patch(`modules/${moduleId}/lesson`, formData);
    } else if (courseId) {
      await instance.patch(`courses/${courseId}/lesson`, formData);
    }
  },
  deleteLessons: async ({
    courseId,
    moduleId,
    lessonIds
  }: {
    courseId?: string;
    moduleId?: string;
    lessonIds: string[];
  }) => {
    if (moduleId) {
      await instance.delete(`modules/${moduleId}/lesson`, { data: { lessonIds } });
    } else if (courseId) {
      await instance.delete(`courses/${courseId}/lesson`, { data: { lessonIds } });
    }
  },
  copyLesson: async ({ lessonId, targetCourseId, targetModuleId }: CopyLessonDto) => {
    await instance.post('courses/copy-lesson', { lessonId, targetCourseId, targetModuleId });
  },
  moveLesson: async ({ lessonId, targetCourseId, targetModuleId }: MoveLessonDto) => {
    await instance.post('courses/move-lesson', { lessonId, targetCourseId, targetModuleId });
  },

  getLesson: async ({
    lessonId,
    sections = true,
    learnedSections = false,
    words = false,
    learnedWords = false,
    favoriteWords = false,
    amountSteps = false
  }: {
    lessonId: string;
    sections?: boolean;
    learnedSections?: boolean;
    words?: boolean;
    learnedWords?: boolean;
    favoriteWords?: boolean;
    amountSteps?: boolean;
  }) => {
    const response = await instance.get<ILesson>(
      `lessons/one/${lessonId}?learned=${learnedSections}&sections=${sections}&words=${words}&learnedWords=${learnedWords}&favoriteWords=${favoriteWords}&amountSteps=${amountSteps}`
    );
    return response.data;
  },
  updateLesson: async ({ lessonId, body }: UpdateLessonDto) => {
    const response = await instance.patch(`lessons/${lessonId}`, body);
    return response.data;
  },
  updateLessonOrders: async (data: UpdateOrderData[]) => {
    const response = await instance.put<ILesson[]>('lessons/orders', { data });
    return response.data;
  },
  addSections: async (lessonId: string, sections: CreateSectionData[]) => {
    const response = await instance.patch(`lessons/${lessonId}/section`, { sections });
    return response.data;
  },
  deleteSections: async (lessonId: string, sectionIds: string[]) => {
    await instance.delete(`lessons/${lessonId}/section`, { data: { sectionIds } });
  },
  addWords: async (lessonId: string, wordIds: string[]) => {
    await instance.patch(`lessons/${lessonId}/word`, { wordIds });
  },
  deleteWords: async ({ lessonId, wordIds }: DeleteWordsFromLessonDto) => {
    const response = await instance.delete(`lessons/${lessonId}/word`, { data: { wordIds } });
    return response.data;
  },
  createAndAddWords: async (lessonId: string, words: ValidatedWordData[]) => {
    await instance.post(`lessons/${lessonId}/word`, { words });
  },
  getWordsFromFile: async (lessonId: string, file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    const response = await instance.post<ValidatedWordsData>(
      `lessons/${lessonId}/word/file`,
      formData
    );
    return response.data;
  },
  getWordsFromNotionText: async (lessonId: string, text: string) => {
    const response = await instance.post<ValidatedWordsData>(`lessons/${lessonId}/word/markdown`, {
      markdown: text
    });
    return response.data;
  },
  validateWord: async (lessonId: string, validatedWordData: ValidatedWordData) => {
    const response = await instance.post<ValidatedWord>(
      `lessons/${lessonId}/word/validation`,
      validatedWordData
    );
    return response.data;
  },
  uploadLessonImage: async ({ lessonId, image }: UploadLessonImageDto) => {
    const formData = new FormData();
    formData.append('image', image);

    const response = await instance.patch(`/lessons/${lessonId}/image`, formData);
    return response.data;
  },
  copySection: async ({ lessonId, sectionId }: CopySectionDto) => {
    await instance.post(`lessons/${lessonId}/section/${sectionId}`);
  },
  moveSection: async ({ lessonId, sectionId }: MoveSectionDto) => {
    await instance.put(`lessons/${lessonId}/section/${sectionId}`);
  },

  getSection: async (sectionId: string) => {
    const response = await instance.get<ISection>(`sections/one/${sectionId}`);
    return response.data;
  },
  updateSection: async (sectionId: string, data: UpdateSectionData) => {
    await instance.patch(`sections/${sectionId}`, data);
  },
  updateSectionOrders: async ({ body }: UpdateSectionOrdersDto) => {
    const response = await instance.put<ISection[]>('sections/orders', { data: body });
    return response.data;
  },

  updateStepsOrder: async (data: UpdateStepsOrderData) => {
    const response = await instance.put<IStep[]>('steps/orders', data);
    return response.data;
  },

  addTextStep: async (sectionId: string, data: CreateTextStepData) => {
    const response = await instance.patch<ITextStep>(`sections/${sectionId}/text-step`, data);
    return response.data;
  },
  updateTextStep: async (stepId: string, data: UpdateTextStepData) => {
    const response = await instance.patch<ITextStep>(`steps/${stepId}/text-step`, data);
    return response.data;
  },
  deleteTextStep: async (sectionId: string, stepId: string) => {
    await instance.delete(`sections/${sectionId}/text-step/${stepId}`);
  },

  addVideoStep: async (sectionId: string, data: CreateVideoStepData) => {
    const response = await instance.patch<IVideoStep>(`sections/${sectionId}/video-step`, data);
    return response.data;
  },
  updateVideoStep: async (stepId: string, data: UpdateVideoStepData) => {
    const response = await instance.patch<IVideoStep>(`steps/${stepId}/video-step`, data);
    return response.data;
  },
  deleteVideoStep: async (sectionId: string, stepId: string) => {
    await instance.delete(`sections/${sectionId}/video-step/${stepId}`);
  },

  addMatchingPairsStep: async (sectionId: string, data: ICreateMatchingPairsStepData) => {
    const response = await instance.patch<IMatchingPairsStep>(
      `sections/${sectionId}/matching-pairs-step`,
      data
    );
    return response.data;
  },
  updateMatchingPairsStep: async (stepId: string, data: IUpdateMatchingPairsStepData) => {
    const response = await instance.patch<IMatchingPairsStep>(
      `steps/${stepId}/matching-pairs-step`,
      data
    );
    return response.data;
  },
  deleteMatchingPairsStepBySectionAndStepIds: async (sectionId: string, stepId: string) => {
    await instance.delete(`sections/${sectionId}/matching-pairs-step/${stepId}`);
  },

  addChooseAnswerStep: async (sectionId: string, data: CreateChooseAnswerStepData) => {
    const response = await instance.patch<IChooseAnswerStep>(
      `sections/${sectionId}/choose-answer-step`,
      data
    );
    return response.data;
  },
  updateChooseAnswerStep: async (stepId: string, data: UpdateChooseAnswerStepData) => {
    const response = await instance.patch<IChooseAnswerStep>(
      `steps/${stepId}/choose-answer-step`,
      data
    );
    return response.data;
  },
  deleteChooseAnswerStep: async (sectionId: string, stepId: string) => {
    await instance.delete(`sections/${sectionId}/choose-answer-step/${stepId}`);
  },

  addChooseSentenceStep: async ({ sectionId, body }: CreateChooseSentenceStepDto) => {
    const response = await instance.patch<ChooseSentenceStep>(
      `sections/${sectionId}/choose-sentence-step`,
      body
    );
    return response.data;
  },
  updateChooseSentenceStep: async ({ stepId, body }: UpdateChooseSentenceStepDto) => {
    const response = await instance.patch<ChooseSentenceStep>(
      `steps/${stepId}/choose-sentence`,
      body
    );
    return response.data;
  },
  deleteChooseSentenceStep: async ({ sectionId, stepId }: DeleteChooseSentenceStepDto) => {
    await instance.delete(`sections/${sectionId}/choose-sentence-step/${stepId}`);
  },

  addMakingSentenceStep: async (sectionId: string, data: CreateMakingSentenceStepData) => {
    const response = await instance.patch<MakingSentenceStep>(
      `sections/${sectionId}/making-sentence-step`,
      data
    );
    return response.data;
  },
  updateMakingSentenceStep: async (stepId: string, data: UpdateMakingSentenceStepData) => {
    const response = await instance.patch<MakingSentenceStep>(
      `steps/${stepId}/making-sentence-step`,
      data
    );
    return response.data;
  },
  deleteMakingSentenceStep: async (sectionId: string, stepId: string) => {
    await instance.delete(`sections/${sectionId}/making-sentence-step/${stepId}`);
  },

  addFillingWordsStep: async (sectionId: string, data: CreateFillingWordsStepData) => {
    const response = await instance.patch<FillingWordsStep>(
      `sections/${sectionId}/filling-missing-words-step`,
      data
    );
    return response.data;
  },
  updateFillingWordsStep: async (stepId: string, data: UpdateFillingWordsStepData) => {
    const response = await instance.patch<FillingWordsStep>(
      `steps/${stepId}/filling-missing-words-step`,
      data
    );
    return response.data;
  },
  deleteFillingWordsStep: async (sectionId: string, stepId: string) => {
    await instance.delete(`sections/${sectionId}/filling-missing-words-step/${stepId}`);
  },

  addInsertingWordsStep: async (sectionId: string, data: CreateInsertingWordsStepData) => {
    const response = await instance.patch<InsertingWordsStep>(
      `sections/${sectionId}/inserting-missing-words-step`,
      data
    );
    return response.data;
  },
  updateInsertingWordsStep: async (stepId: string, data: UpdateInsertingWordsStepData) => {
    const response = await instance.patch<InsertingWordsStep>(
      `steps/${stepId}/inserting-missing-words-step`,
      data
    );
    return response.data;
  },
  deleteInsertingWordsStep: async (sectionId: string, stepId: string) => {
    await instance.delete(`sections/${sectionId}/inserting-missing-words-step/${stepId}`);
  },

  addDialogStep: async ({ sectionId, body }: AddDialogStepDto) => {
    const response = await instance.patch<DialogStep>(`sections/${sectionId}/dialog-step`, body);
    return response.data;
  },
  updateDialogStep: async ({ stepId, body }: UpdateDialogStepDto) => {
    const response = await instance.patch<DialogStep>(`steps/${stepId}/dialog-step`, body);
    return response.data;
  },
  deleteDialogStep: async ({ sectionId, stepId }: DeleteDialogStepDto) => {
    await instance.delete(`sections/${sectionId}/dialog-step/${stepId}`);
  },

  setStepImage: async ({ stepId, type, image }: SetStepImageDto) => {
    const formData = new FormData();
    formData.append('image', image);
    formData.append('type', type);

    await instance.patch(`/steps/${stepId}/image`, formData);
  },
  setStepAudio: async (stepId: string, audio: File, type: STEP_TYPES) => {
    const formData = new FormData();
    formData.append('audio', audio);
    formData.append('type', type);

    await instance.patch(`/steps/${stepId}/audio`, formData);
  },
  getUrlFromAudio: async (audio: File) => {
    const formData = new FormData();
    formData.append('audio', audio);

    const response = await instance.post<{ url: string }>('/steps/audio/link', formData);
    return response.data;
  },
  getUrlFromImage: async (image: File) => {
    const formData = new FormData();
    formData.append('image', image);

    const response = await instance.post<{ url: string }>('/steps/image/link', formData);
    return response.data;
  },
  getAudioUrl: async (textForAudio: string) => {
    const response = await instance.post<{ url: string }>('steps/tts', { textForAudio });
    return response.data;
  }
};
