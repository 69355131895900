import { FC } from 'react';
import styles from './WordReference.module.css';
import { IWordReference } from 'api/api-words';

interface WordReferenceProps {
  reference: IWordReference;
  className?: string;
}

const WordReference: FC<WordReferenceProps> = ({ reference, className }) => {
  if (reference.thematicDictionaryId) {
    return (
      <div className={`${styles.root} ${className}`}>
        <div className={styles.subtitle}>Тема</div>
        <div>{reference.thematicDictionary?.title}</div>
      </div>
    );
  }

  return (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.subtitle}>Уровень {reference.course?.level}</div>
      <div className={styles.title}>{reference.course?.title}</div>
    </div>
  );
};

export default WordReference;
