import { FC, useMemo } from 'react';
import { Control, UseFormGetValues, useController } from 'react-hook-form';

import imagePlaceholder from 'assets/images/png/courseBg.png';
import { NOTIFICATION_FOR, REPEAT_TYPE, SEND_TIME } from 'models';
import { notificationStatusOptions } from '../../const';
import { FormValues } from '../../model';
import { translateMonth, translateWeekday } from '../../utils';

import { Select } from 'components/atoms';
import Button from 'components/atoms/Button';
import { IconBack, IconCalendar, IconClock } from 'components/atoms/icons';
import { FORM_ERROR_MESSAGES } from 'consts';
import { Recipients } from '..';

interface Step4Props {
  control: Control<FormValues>;
  isDirty: boolean;
  getValues: UseFormGetValues<FormValues>;
  onPrev: () => void;
  saveAsDraft: () => void;
  isLoading: boolean;
  isEdit?: boolean;
}

const Step4: FC<Step4Props> = ({
  control,
  isDirty,
  getValues,
  onPrev,
  saveAsDraft,
  isLoading,
  isEdit = false
}) => {
  const { field: imageField } = useController({
    name: 'image',
    control
  });

  const imageUrl = useMemo<string>(() => {
    if (imageField.value) {
      const objectUrl = URL.createObjectURL(imageField.value);
      return objectUrl;
    }
    return imagePlaceholder;
  }, [imageField.value]);

  return (
    <div className='flex flex-1 flex-col'>
      <h4 className='mb-[30px] text-[18px] font-[500] leading-[21px] text-[#20233A]'>Проверка</h4>

      <div className='mb-[30px] flex items-center gap-[30px]'>
        <div className='h-[100px] w-[100px] border-[1px] border-solid border-[#E9ECF0]'>
          <img src={imageUrl} alt={getValues('title')} className='h-full w-full' />
        </div>

        <div className='flex flex-col'>
          <h5 className='mb-[10px] text-[16px] font-[500] leading-[19px] text-[#20233A]'>
            {getValues('title')}
          </h5>
          <p className='mb-[10px] text-[16px] leading-[24px] text-[#20233A]'>
            {getValues('message')}
          </p>
        </div>
      </div>

      <div className='mb-[30px]'>
        <h6 className='mb-[8px] text-[13px] font-[500] uppercase leading-[15px] text-[#71798F]'>
          получатели
        </h6>

        {getValues('for') === NOTIFICATION_FOR.ALL ? (
          <div className='flex gap-[8px]'>
            <div
              className={
                'rounded-[27px] bg-[#EFF1F4] px-[24px] py-[22px] ' +
                'text-[16px] font-[500] leading-[19px] text-[#20233A]'
              }
            >
              {getValues('recipientsRole')}
            </div>
          </div>
        ) : (
          <Recipients control={control} />
        )}
      </div>

      <div className={isEdit ? 'mb-[30px]' : 'mb-auto'}>
        <h6 className='mb-[8px] text-[13px] font-[500] uppercase leading-[15px] text-[#71798F]'>
          когда
        </h6>

        {getValues('sendTime') === SEND_TIME.NOW && (
          <div className='flex'>
            <div
              className={
                'rounded-[27px] bg-[#EFF1F4] px-[24px] py-[22px] ' +
                'text-[16px] font-[500] leading-[19px] text-[#20233A]'
              }
            >
              Сейчас
            </div>
          </div>
        )}

        {getValues('sendTime') === SEND_TIME.DATE && (
          <div className='flex gap-[20px]'>
            <div className='flex items-center gap-[10px] py-[20px]'>
              <IconCalendar color='#5770F3' />
              <span className='text-[16px] font-[500] leading-[22px] text-[#71798F]'>
                {getValues('sendDateDay')}
              </span>
            </div>
            <div className='flex items-center gap-[10px] py-[20px]'>
              <IconClock color='#5770F3' />
              <span className='text-[16px] font-[500] leading-[22px] text-[#71798F]'>
                {getValues('sendDateTime')}
              </span>
            </div>
          </div>
        )}

        {getValues('sendTime') === SEND_TIME.REPEAT && (
          <>
            {getValues('repeatType') === REPEAT_TYPE.DAY && (
              <div className='flex gap-[8px]'>
                <div
                  className={
                    'rounded-[27px] bg-[#EFF1F4] px-[24px] py-[22px] ' +
                    'text-[16px] font-[500] leading-[19px] text-[#20233A]'
                  }
                >
                  Каждый день
                </div>
                <div
                  className={
                    'rounded-[27px] bg-[#EFF1F4] px-[24px] py-[22px] ' +
                    'text-[16px] font-[500] leading-[19px] text-[#20233A]'
                  }
                >
                  {getValues('repeatTime')}
                </div>
              </div>
            )}

            {getValues('repeatType') === REPEAT_TYPE.WEEK && (
              <div className='flex gap-[8px]'>
                <div
                  className={
                    'rounded-[27px] bg-[#EFF1F4] px-[24px] py-[22px] ' +
                    'text-[16px] font-[500] leading-[19px] text-[#20233A]'
                  }
                >
                  Каждую неделю
                </div>
                {getValues('repeatDays')?.map((day, index) => (
                  <div
                    key={index}
                    className={
                      'rounded-[27px] bg-[#EFF1F4] px-[24px] py-[22px] ' +
                      'text-[16px] font-[500] leading-[19px] text-[#20233A]'
                    }
                  >
                    {translateWeekday(day)}
                  </div>
                ))}
                <div
                  className={
                    'rounded-[27px] bg-[#EFF1F4] px-[24px] py-[22px] ' +
                    'text-[16px] font-[500] leading-[19px] text-[#20233A]'
                  }
                >
                  {getValues('repeatTime')}
                </div>
              </div>
            )}

            {getValues('repeatType') === REPEAT_TYPE.MONTH && (
              <div className='flex gap-[8px]'>
                <div
                  className={
                    'rounded-[27px] bg-[#EFF1F4] px-[24px] py-[22px] ' +
                    'text-[16px] font-[500] leading-[19px] text-[#20233A]'
                  }
                >
                  Каждый месяц
                </div>
                <div
                  className={
                    'rounded-[27px] bg-[#EFF1F4] px-[24px] py-[22px] ' +
                    'text-[16px] font-[500] leading-[19px] text-[#20233A]'
                  }
                >
                  {getValues('repeatEveryMonthDay')} числа
                </div>
                <div
                  className={
                    'rounded-[27px] bg-[#EFF1F4] px-[24px] py-[22px] ' +
                    'text-[16px] font-[500] leading-[19px] text-[#20233A]'
                  }
                >
                  {getValues('repeatEveryMonthTime')}
                </div>
              </div>
            )}

            {getValues('repeatType') === REPEAT_TYPE.YEAR && (
              <div className='flex gap-[8px]'>
                <div
                  className={
                    'rounded-[27px] bg-[#EFF1F4] px-[24px] py-[22px] ' +
                    'text-[16px] font-[500] leading-[19px] text-[#20233A]'
                  }
                >
                  Каждый год
                </div>
                <div
                  className={
                    'rounded-[27px] bg-[#EFF1F4] px-[24px] py-[22px] ' +
                    'text-[16px] font-[500] leading-[19px] text-[#20233A]'
                  }
                >
                  {getValues('repeatEveryYearDay')} числа
                </div>
                <div
                  className={
                    'rounded-[27px] bg-[#EFF1F4] px-[24px] py-[22px] ' +
                    'text-[16px] font-[500] leading-[19px] text-[#20233A]'
                  }
                >
                  {translateMonth(getValues('repeatEveryYearMonth') as string)}
                </div>
                <div
                  className={
                    'rounded-[27px] bg-[#EFF1F4] px-[24px] py-[22px] ' +
                    'text-[16px] font-[500] leading-[19px] text-[#20233A]'
                  }
                >
                  {getValues('repeatEveryYearTime')}
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {isEdit && (
        <div className='mb-auto'>
          <h6 className='mb-[8px] text-[13px] font-[500] uppercase leading-[15px] text-[#71798F]'>
            статус
          </h6>

          <Select
            name='status'
            placeholder='Статус'
            options={notificationStatusOptions}
            control={control}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
            }}
            className='w-[240px]'
          />
        </div>
      )}

      <div className='mt-[30px] flex justify-between'>
        <Button
          type='button'
          variant='secondary'
          title='Назад'
          iconPosition='left'
          icon={<IconBack className='text-[#71798F]' />}
          onClick={onPrev}
          isDisabled={isLoading}
        />
        <div className='flex gap-[8px]'>
          <Button
            type='button'
            variant='secondary'
            title='Сохранить как черновик'
            onClick={saveAsDraft}
            isDisabled={!isDirty || isLoading}
          />
          <Button
            type='submit'
            variant='primary'
            title='Готово'
            className='min-w-[198px]'
            isDisabled={!isDirty}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default Step4;
