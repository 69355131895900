import { apiWords } from 'api';
import { GenerateIcon, IconClose } from 'components/atoms/icons';
import Modal from 'components/atoms/Modal';
import { handleError } from 'helpers/handleError';
import { FC, useState } from 'react';
import { useMutation } from 'react-query';
import cls from './GeneratedWordImageModal.module.css';
import EmptyWord from 'assets/images/png/emptyWordBig.jpg';
import Button from 'components/atoms/Button';
import { notifySuc } from 'helpers/notification';

interface GeneratedWordImageModalProps {
  wordId: string;
  handleClose: () => void;
  afterUpload?: () => void;
}

const GeneratedWordImageModal: FC<GeneratedWordImageModalProps> = ({
  wordId,
  handleClose,
  afterUpload
}) => {
  const [selectedImage, setSelectedImage] = useState<string>();
  const [images, setImages] = useState<{ id: string; image: string | null }[]>(
    Array.from({ length: 4 }, (_, index) => ({ id: index.toString(), image: null }))
  );

  const { mutate: generateImage, isLoading: isGenerateImageLoading } = useMutation(
    () => {
      return apiWords.generateImage({ wordId });
    },
    {
      onSuccess(data) {
        setImages(data.map((image, index) => ({ id: index.toString(), image })));
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const { mutate: uploadImage, isLoading: isUploadImageLoading } = useMutation(
    () => {
      return apiWords.uploadBase64Image({ wordId, base64: images[Number(selectedImage)].image! });
    },
    {
      onSuccess() {
        afterUpload?.();
        notifySuc('Изображение загружено');
        handleClose();
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  return (
    <Modal onClose={handleClose}>
      <div className={cls.container}>
        <header className={cls.header}>
          <h2 className={cls.title}>Сгенерировать картинку</h2>
          <button onClick={handleClose}>
            <IconClose />
          </button>
        </header>
        <div className={cls.imagesBlock}>
          {images.map(({ id, image }) => (
            <img
              key={id}
              src={image ? `data:image/jpeg;base64,${image}` : EmptyWord}
              onClick={() => {
                if (isUploadImageLoading || isGenerateImageLoading || !image) return;
                if (selectedImage === id) setSelectedImage(undefined);
                else setSelectedImage(id);
              }}
              className={`${selectedImage === id ? cls.selectedImage : ''} ${
                image && selectedImage && selectedImage !== id ? 'opacity-50' : ''
              }`}
              alt=''
            />
          ))}
        </div>
        <Button
          title='Сгенерировать'
          iconPosition='left'
          icon={<GenerateIcon />}
          onClick={generateImage}
          isLoading={isGenerateImageLoading || isUploadImageLoading}
          className={cls.generateButton}
          isDisabled={!!selectedImage}
        />
        <div className={cls.buttons}>
          <Button title='Отменить' variant='secondary' onClick={handleClose} />
          <Button
            title='Добавить'
            onClick={uploadImage}
            isDisabled={!selectedImage}
            isLoading={isGenerateImageLoading || isUploadImageLoading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default GeneratedWordImageModal;
